import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../../components/it/layout"
import SEO from "../../components/seo"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import ReactTimeAgo from "react-time-ago"
import JavascriptTimeAgo from "javascript-time-ago"
import it from "javascript-time-ago/locale/it"
import Masonry from "react-masonry-component"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
      query {
          cases: allDirectusCaseHistory(sort: {order: DESC, fields: date}) {
              nodes {
                  title
                  subtitle
                  slug
                  image {
                      localFile {
                          childImageSharp {
                              fluid {
                                  ...GatsbyImageSharpFluid
                              }
                          }
                      }
                  }
                  date
              }
          }
          image: file(relativePath: { eq: "case-history.jpg" }) {
              childImageSharp {
                  fluid {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  JavascriptTimeAgo.locale(it)

  const fixDateForAllBrowsers = dateString => dateString.replace(/-/g, '/');

  const seo = {
    title: "Case History",
    description: "Consulta i nostri Case History",
  }

  return (
    <Layout>
      <SEO title={seo.title} description={seo.description} lang={"it"} url={"/case-history"} />
      <Container fluid>
        <Row className={"site-content"}>
          <Col md={12} lg={6} xl={5} className={"offset-lg-1"}>
            <h1>Case History</h1>
            <Masonry
              className={"case-history mt-3"} // default ''
            >
              { data.cases.nodes.map((item, key) => (
                <Link to={`/it/case-history/${item.slug}`} key={key} className={"case"}>
                  <Card key={key}>
                    <Img className={"card-img-top"} variant="top" fluid={item.image.localFile.childImageSharp.fluid} style={{ width: `100%`, height: `100%` }} />
                    <Card.Body>
                      <Card.Title className={"mb-0"}>{item.title}</Card.Title>
                      <small className={"text-muted"}><ReactTimeAgo date={ fixDateForAllBrowsers(item.date) } locale={"it"}/></small>
                      <Card.Text className={"mt-2"}>{item.subtitle}</Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              ))}
            </Masonry>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={5} className={"offset-lg-1 p-0"}>
            <Img fluid={data.image.childImageSharp.fluid} style={{ width: `100%` }} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
